<template>
  <div class="wrap">
    <!-- 页头logo -->
    <!-- <header class="header">
      <img src="@/static/images/ic_logo_bottom@2x.png" alt="" />
      <img src="@/static/images/ic_logo_r_top@2x.png" alt="" />
    </header> -->
    <!-- 导航栏 -->
    <!-- <Nav /> -->
    <!-- <nav>
      <div class="nav">
        <ul class="nav_item">
          <li>关于安联</li>
          <li>产品中心</li>
          <li>产品服务</li>
          <li>在线投保</li>
          <li>新闻公告</li>
          <li>职业生涯</li>
          <li>服务通道</li>
          <li>员工通道</li>
        </ul>
        <img src="@/static/images/ic_search@2x.png" alt="" class="search" />
      </div>
      <div class="information">
        <span>公开信息披露</span>｜
        <span>客户投诉处理流程</span>
      </div>
    </nav> -->
    <!-- banner -->
    <div class="banner">
      <!-- <img src="@/static/images/banner_01@2x.png" alt="" /> -->
      <el-carousel height="32vw"  arrow="never">
        <template v-if="bannerList">
          <el-carousel-item v-for="item in bannerList" :key="item.zid">
            <!-- <h3 class="small">{{ item }}</h3> -->
            <a :href="item.bannerLink" target="_blank">
              <img
                  :src="item.bannerImg"
                  alt=""
                  class="small"
                  style="width: 100%; height: 100%"
              />
            </a>
          </el-carousel-item>
        </template>
        <template v-else>
          <el-carousel-item v-for="item in bannerImg" :key="item.zid">
            <!-- <h3 class="small">{{ item }}</h3> -->
            <a :href="item.bannerLink" target="_blank">
              <img
                  :src="item"
                  alt=""
                  class="small"
                  style="width: 100%; height: 100%"
              />
            </a>

          </el-carousel-item>
        </template>

      </el-carousel>
    </div>
    <div class="demand">
      <main>
        <div class="demand_title">您已经有明确的保险需求了？</div>
        <div class="product">浏览我们的产品和服务</div>
        <ul class="demand_main">
          <li
            v-for="(item, index) in imgIcon"
            :key="index"
            @mouseenter="mouseenter(index)"
            :class="[current === index ? 'current' : '']"
            @click="jumpTo(index)"
          >
            <div class="pic"></div>
            <!-- <img src="@/static/images/ic_program_01_w@2x.png" alt="" /> -->
            <p>{{ item.title }}</p>
            <div>{{ item.text }}</div>
          </li>
        </ul>
      </main>
    </div>
    <!-- 客户服务 -->
    <div class="service">
      <div class="service_title title">客户服务</div>
      <p class="service_title-line title-line"></p>
      <p class="service_title-line2 title-line2"></p>
      <main class="hot_products">
        <section v-for="item in customer" :key="item.title">
          <div class="h5">{{ item.title }}</div>
          <ul>
            <li
              v-for="items in item.children"
              :key="items.title"
            >
              <template v-if="htt(items.link)">
                <a :href="items.link" target="_blank">
                  <img :src="items.img" alt="" />
                  <p>{{ items.title }}</p>
                </a>
              </template>
              <template v-else>
                <a @click="jumpTo1(items.link)">
                  <img :src="items.img" alt="" />
                  <p>{{ items.title }}</p>
                </a>
              </template>

            </li>
            <!--            <li>-->
            <!--              <img src="@/static/images/Newspaper@2x.png" alt="" />-->
            <!--              <p>保险消费指南</p>-->
            <!--            </li>-->
            <!--            <li>-->
            <!--              <img src="@/static/images/Search@2x.png" alt="" />-->
            <!--              <p>保单查询</p>-->
            <!--            </li>-->
            <!--            <li>-->
            <!--              <img src="@/static/images/Communication@2x.png" alt="" />-->
            <!--              <p>保单售后服务</p>-->
            <!--            </li>-->
            <!--            <li>-->
            <!--              <img src="@/static/images/Care Insurance@2x.png" alt="" />-->
            <!--              <p>安联理赔服务</p>-->
            <!--            </li>-->
            <!--            <li>-->
            <!--              <img src="@/static/images/Arrow Direction Path@2x.png" alt="" />-->
            <!--              <p>更多</p>-->
            <!--            </li>-->
          </ul>
        </section>
        <!--        <section>-->
        <!--          <div class="h5">团队客户</div>-->
        <!--          <ul>-->
        <!--            <li>-->
        <!--              <img src="@/static/images/Communication@2x.png" alt="" />-->
        <!--              <p>保单售后服务</p>-->
        <!--            </li>-->
        <!--            <li>-->
        <!--              <img src="@/static/images/Hand Tray@2x.png" alt="" />-->
        <!--              <p>“享”福利</p>-->
        <!--            </li>-->
        <!--            <li>-->
        <!--              <img src="@/static/images/Search@2x.png" alt="" />-->
        <!--              <p>保单查询</p>-->
        <!--            </li>-->
        <!--            <li>-->
        <!--              <img src="@/static/images/Laptop@2x.png" alt="" />-->
        <!--              <p>团险自助平台</p>-->
        <!--            </li>-->
        <!--          </ul>-->
        <!--        </section>-->
      </main>
    </div>
    <!-- 新闻公告 -->
    <div class="news">
      <div class="news_title title">新闻公告</div>
      <p class="news_title-line title-line"></p>
      <p class="news_title-line2 title-line2"></p>
      <main class="hot_products">
        <section class="details">
          <main class="details_introduce"  v-if="newsData1" @click="details(newsData1.zid)">
            <span class="details_day">{{newsData1.newsDate}}</span>
            <p class="details_title">
              {{newsData1.newsTitle}}
            </p>
            <p class="details_content">
             {{newsData1.newsSummary}}
            </p>
            <div class="goDetails">
              <img src="@/static/images/ic_arrow_black@2x.png" alt="" />
            </div>
          </main>
          <main class="details_introduce" v-else>
            <empty></empty>
          </main>
          <div class="news_img">
            <img :src="bannerImg1 ?bannerImg1.bannerImg :newImg" alt="" />
          </div>
        </section>
        <section class="details">
          <div class="news_img2">
            <img :src="bannerImg2 ?bannerImg2.bannerImg :img" alt="" />
          </div>

          <div class="details_two">
            <main class="details_introduce2" v-if="newsData2" @click="details(newsData2.zid)">
              <span class="details_day">{{newsData2.newsDate}}</span>
              <p class="details_title">
                {{newsData2.newsTitle}}
              </p>
              <p class="details_content">
                {{newsData2.newsSummary}}
              </p>
              <div class="goDetails">
                <img src="@/static/images/ic_arrow_black@2x.png" alt="" />
              </div>
            </main>
            <main class="details_introduce2" v-else>
              <empty></empty>
            </main>
            <main class="details_introduce2"  v-if="newsData3" @click="details(newsData3.zid)">
              <span class="details_day">{{newsData3.newsDate}}</span>
              <p class="details_title">
                {{newsData3.newsTitle}}
              </p>
              <p class="details_content">
                {{newsData3.newsSummary}}
              </p>
              <div class="goDetails">
                <img src="@/static/images/ic_arrow_black@2x.png" alt="" />
              </div>
            </main>
            <main class="details_introduce2" v-else>
              <empty></empty>
            </main>
          </div>
        </section>
      </main>
    </div>

    <!-- 监管 -->
    <div class="regulatory_box">
      <div class="regulatory">
        <div>
          <p>公司最近季度的综合偿付能力充足率、风险综合评级符合监管要求，</p>
          <p>
            详情请扫描右侧二维码或通过链接
            <a href="solvencyCapacity/AZ01005"
              >（https://www.allianz.com.cn/solvencyCapacity/AZ01005）</a
            >了解
          </p>
          <p>
            The company's comprehensive solvency ratio and integrated risk
            rating in the most recent quarter meet the regulator’s requirement.
            For details, please scan the QR code on the right or learn through
            the link<a href="solvencyCapacity/AZ01005"
              >（https://www.allianz.com.cn/solvencyCapacity/AZ01005）</a
            >
          </p>
        </div>
        <div class="qr_code">
          <img src="@/static/images/qcCode.png" alt="">
        </div>
      </div>
    </div>
    <!-- 页脚 -->
    <!-- <footer>
      <div class="link">
        <ul>
          <li>帮助中心</li>
          <li>会员中心</li>
          <li>保单续保</li>
          <li>理赔服务</li>
          <li>资料下载</li>
          <li>常见问题</li>
          <li>隐私政策</li>
        </ul>
        <ul>
          <li>安联品牌</li>
          <li>品牌故事</li>
          <li>品牌宗旨</li>
          <li>偿付能力信息披露</li>
          <li>消费者保护监管评价</li>
          <li>公开信息披露</li>
          <li>互联网保险信息披露</li>
        </ul>
        <ul>
          <li>友情链接</li>
          <li>安联集团</li>
          <li>银保监会</li>
          <li>安联亚太</li>
        </ul>
      </div>
      <div class="public">
        <div class="public_pic">
          <img src="@/static/images/ic_logo_bottom@2x.png" alt="" />
          <div>
            <div></div>
            <p>公众号</p>
          </div>
          <div>
            <div></div>
            <p>App</p>
          </div>
        </div>
        <div class="footer">
          © 安联（中国）保险控股有限公司 2019. All Rights
          Reserved.沪ICP备19047349
        </div>
      </div>
    </footer> -->
  </div>
</template>

<script>
import { homeBanner,newsList } from "@/api/home";
import empty from "@/components/empty";
// import $ from 'jquery';
const imgIcon = [
  {
    link: "/IncreaseWealth",
    title: "财富增值",
    text: "兼具保障和理财的功能",
  },
  {
    link: "",
    title: "成长快乐",
    text: "成就孩子光明未来",
  },
  {
    link: "",
    title: "幸福晚年",
    text: "维持和提高老年生活水平",
  },
  {
    link: "",
    title: "健康生活",
    text: "为您的健康保驾护航",
  },

  {
    link: "",
    title: "安心保障",
    text: "协助分担保家庭责任",
  },
  {
    link: "",
    title: "出行无忧",
    text: "全面多样的意外保险",
  },
  {
    link: "",
    title: "财富传承",
    text: "一生成就世代相传",
  },
];
const customer = [
  {
    title: "个人客户",
    children: [
      {
        link: "/personalCustomer/insuranceAfterSales/AZ01801",
        img: require("@/static/images/Pages Documents@2x.png"),
        title: "保险购买指南",
      },
      {
        link: "/personalCustomer/insuranceAfterSales/AZ01802",
        img: require("@/static/images/Newspaper@2x.png"),
        title: "保单售后服务",
      },
      {
        link: "/personalCustomer/insuranceAfterSales/AZ01803",
        img: require("@/static/images/Search@2x.png"),
        title: "安联理赔服务",
      },
      {
        link: "/personalCustomer/insuranceAfterSales/AZ01804",
        img: require("@/static/images/Communication@2x.png"),
        title: "核保体检机构查询",
      },
      {
        // link: "https://www.allianz.com.cn/upload/pdf/202105111039356099ee6745e0343292.pdf",
        link: "https://www.allianz.com.cn/allianz/fad37dd449c64d24a9f4579fc03f527f.pdf",
        img: require("@/static/images/Care Insurance@2x.png"),
        title: "昂贵及质重医院查询",
      },
      {
        link: "",
        img: require("@/static/images/Arrow Direction Path@2x.png"),
        title: "更多",
      },
    ],
  },
  {
    title: "团体客户",
    children: [
      {
        link: "/personalCustomer/insuranceAfterSales/AZ01901",
        img: require("@/static/images/Communication@2x.png"),
        title: "保单售后服务",
      },
      {
        link: "https://pts.allianz.com.cn/zdal/merchant-web/#/login",
        img: require("@/static/images/Hand Tray@2x.png"),
        title: "“享”福利",
      },
      {
        link: "https://pts.allianz.com.cn/wsm/login",
        img: require("@/static/images/Search@2x.png"),
        title: "关爱全家自助服务",
      },
      {
        link: "https://pts.allianz.com.cn/azclportal/faces/hrlogin?_adf.ctrl-state=15hlvl9sm4_19",
        img: require("@/static/images/Laptop@2x.png"),
        title: "团险客户查询",
      },
    ],
  },
];
export default {
  data() {
    return {
      imgIcon,
      customer,
      img: require("@/static/images/ic_logo_bottom@2x.png"),
      newImg:require("@/static/images/06(1).jpg"),
      bannerImg: [
        require("@/static/images/06(1).jpg"),
        require("@/static/images/06(1).jpg"),
        require("@/static/images/06(1).jpg"),
      ],
      bannerList: [],
      homeNews:[],
      current: undefined,
      newsList:[],
      newsData1:undefined,
      newsData2:undefined,
      newsData3:undefined,
      bannerImg1:undefined,
      bannerImg2:undefined,
    };
  },
  components: { empty },
  created() {
    this.current = 0;
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    this.getHomeBanner();
    // this.request();
    this.getNewsList();
  },
  mounted() {
    // console.log("1",$('.hot_products>section>ul>li:last-child').eq(0))
  },
  methods: {

    htt(str){
      var reg =/^(http|https):\/\/.*/g;
      let flag = reg.test(str)
      // console.log(flag)
      return flag
    },
    getStr(string, str) {
      console.log(string, str);
      let str_before = string.split(str)[0];
      return str_before;
    },
    //首页banner图
    getHomeBanner() {
      const [timeStamp, sign] = this.$getSign();
      homeBanner({
        timeStamp,
        sign,
      }).then((res) => {
        console.log("homeList", res.data);
        this.bannerList = res.data.data;
        // this.bannerList.forEach((item) =>{
        //   console.log(item.sort((a,b)=>a.zid-b.zid))
        // })
        this.bannerList.sort((a, b) => a.categoryShowindex - b.categoryShowindex);
        console.log("sort", this.bannerList);
      });
    },
    //首页新闻
    getNewsList() {
      const [timeStamp, sign] = this.$getSign();
      newsList({
        timeStamp,
        sign,
      }).then((res) => {
        console.log("newList", res.data);
        this.newsList = res.data.data;
        this.newsList.newsList.forEach((res) =>{
          console.log("res",res)
          if (res.newsType == "AZ01401"){
            this.newsData1 = res
          }else if (res.newsType == "AZ01402"){
            this.newsData2 = res
          }else if (res.newsType == "AZ01403"){
            this.newsData3 = res
          }
        })
        console.log("this.newsData1",this.newsData1)
        this.bannerImg1 = this.newsList.bannerList[0];
        this.bannerImg2 = this.newsList.bannerList[1];
      });
    },

    jumpTo(index) {
      this.$router.push(`/IncreaseWealth?index=${index}&categoryType=0`);
    },
    jumpTo1(menu) {
      console.log(menu);
      this.$router.push(menu);
      // $('.hot_products>section>ul>li:last-child').eq(0).click(){
      //   this.$message.error("123")
      // }
    },
    //保险需求tab
    mouseenter(index) {
      console.log(index);
      this.current = index;
    },
    details(zid) {
      console.log("zid", zid)
      this.$router.push(`/newsDetails/?zid=${zid}`);
    },
  },
};
</script>

<style lang="less" scoped>
* {
  padding: 0;
  margin: 0;
}

li {
  list-style: none;
}

a {
  text-decoration: none;
}

.wrap {
  width: 100%;
  // height: 5000px;

  .banner {
    width: 100%;
    height: 32vw;
    a{
      width: 100%;
      height: 100%;
      > img {
        width: 100%;
        height: 100%;
        cursor: pointer;
      }
    }

  }

  .title {
    width: 20rem;
    font-size: 4rem;
    margin: auto;
    text-align: center;
    margin-bottom: 1.3021rem;
    margin-top: 3rem;
  }

  .title-line {
    width: 41rem;
    height: 1px;
    margin: auto;
  }

  .title-line2 {
    width: 10rem;
    height: 0.3125rem;
    margin: auto;
  }

  .goDetails {
    width: 2rem;
    height: 1.625rem;
    margin: 0 0.5208rem;
    cursor: pointer;

    > img {
      width: 100%;
      height: 100%;
    }
  }

  .demand {
    width: 100%;
    height: 40vw;
    background: #dfeff2;

    > main {
      width: 80vw;
      margin: auto;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      .demand_title {
        font-size: 4rem;
        margin: 4.6042rem 0 0 0;
      }

      .product {
        font-size: 2.5rem;
        margin: 2.0417rem 0 4.6042rem 0;
      }

      .demand_main {
        width: 64vw;
        height: 25vw;
        background: #fff;
        margin: auto;
        display: flex;
        flex-wrap: wrap;

        > li {
          background-color: #fff;
          width: 25%;
          height: 50%;
          display: flex;
          flex-direction: column;
          align-items: center;
          font-size: 2rem;
          color: #000;
          justify-content: center;
          cursor: pointer;

          > p {
            font-size: 2rem;
            margin: 1.5rem 0 1rem 0;
          }

          .pic {
            width: 6rem;
            height: 6rem;
          }

          &:hover {
            background-color: #003781;
            color: #fff;
          }
        }

        > li:first-child {
          .pic {
            background: url("../static/images/ic_program_01@2x.png");
            background-size: 100% 100%;
          }

          &.current {
            .pic {
              background-image: url("../static/images/ic_program_01_w@2x.png");
              background-size: 100% 100%;
            }
          }
        }

        // 当前移入
        .current {
          background-color: #003781;
          color: #fff;
        }

        > li:nth-child(4) {
          .pic {
            background: url("../static/images/ic_program_02@2x.png");
            background-size: 100% 100%;
          }

          &.current {
            .pic {
              background: url("../static/images/ic_program_02_w@2x.png");
              background-size: 100% 100%;
            }
          }
        }

        > li:nth-child(3) {
          .pic {
            background: url("../static/images/ic_program_03@2x.png");
            background-size: 100% 100%;
          }

          &.current {
            .pic {
              background: url("../static/images/ic_program_03_w@2x.png");
              background-size: 100% 100%;
            }
          }
        }

        > li:nth-child(2) {
          .pic {
            background: url("../static/images/ic_program_07@2x.png");
            background-size: 100% 100%;
          }

          &.current {
            .pic {
              background: url("../static/images/ic_program_07_w@2x.png");
              background-size: 100% 100%;
            }
          }
        }

        > li:nth-child(5) {
          .pic {
            background: url("../static/images/ic_program_04@2x.png");
            background-size: 100% 100%;
          }

          &.current {
            .pic {
              background: url("../static/images/ic_program_04_w@2x.png");
              background-size: 100% 100%;
            }
          }
        }

        > li:nth-child(6) {
          .pic {
            background: url("../static/images/ic_program_05@2x.png");
            background-size: 100% 100%;
          }

          &.current {
            .pic {
              background: url("../static/images/ic_program_05_w@2x.png");
              background-size: 100% 100%;
            }
          }
        }

        > li:nth-child(7) {
          .pic {
            background: url("../static/images/ic_program_06@2x.png");
            background-size: 100% 100%;
          }

          &.current {
            .pic {
              background: url("../static/images/ic_program_06_w@2x.png");
              background-size: 100% 100%;
            }
          }
        }
      }
    }
  }

  .service {
    .service_title-line {
      background-color: #13a0d3;
    }

    .service_title-line2 {
      background-color: #13a0d3;
    }

    > main {
      width: 80vw;
      height: 32vw;
      margin: auto;
      margin-top: 4.5313rem;
      display: flex;
      justify-content: space-between;

      section {
        > .h5 {
          font-size: 3.8333rem;
          color: #fff;
          text-align: center;
          margin: 4.6rem 0 0 0;
        }

        > ul {
          width: 100%;
          display: flex;
          //justify-content: center;
          text-align: center;
          flex-wrap: wrap;
          padding: 0 2.6042rem;
          box-sizing: border-box;

          > li {

            &:hover {
              cursor: pointer;

              p {
                border-bottom: 1px solid;
              }
            }
            >a{
              > img {
                width: 6.6042rem;
              }

              > p {
                color: #fff;
                font-size: 2rem;
                margin-top: 1.35rem;
                height: 3rem;
              }
            }

          }
        }
      }

      > section:first-child {
        width: 50%;
        height: 32vw;
        background-color: #007ab3;

        li {
          width: 33%;
          height: 13vw;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-content: center;
          align-items: center;
        }
      }

      > section:last-child {
        width: 50%;
        height: 32vw;
        background-color: #00908d;

        li {
          width: 50%;
          height: 13.5vw;
          display: flex;
          flex-direction: column;
          text-align: center;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }

  .news {
    width: 80vw;
    height: 60vw;
    margin: 4.5313rem auto;

    > .news_title-line {
      background-color: #004b98;
    }

    > .news_title-line2 {
      background-color: #004b98;
      margin-bottom: 3rem;
    }

    > main {
      > .details {
        width: 100%;
        display: flex;

        .details_day {
          font-size: 2.5rem;
          //margin-bottom: 2rem;
        }

        .details_title {
          font-size: 2.5rem;
          font-weight: 700;
          display: -webkit-box;
          overflow: hidden;
          white-space: normal;
          text-overflow: ellipsis;
          word-wrap: break-word;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }

        .details_content {
          font-size: 1.8rem;

          -webkit-line-clamp: 4;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        > .details_introduce {
          width: 27vw;
          display: flex;
          flex-wrap: wrap;
          //align-content: space-evenly;
          padding: 20px;
          box-sizing: border-box;
          flex-direction: column;
          justify-content: space-evenly;
          cursor: pointer;
          /deep/ .empty_box {
            margin: 0;
            width: 20vw;
            height: auto;
          }
        }

        .news_img {
          width: 53vw;
          height: 25vw;

          img {
            width: 100%;
            height: 100%;
          }
        }

        .news_img2 {
          width: 27vw;
          height: 25vw;
          > img {
            width: 100%;
            height: 100%;
          }
        }

        .details_introduce2 {
          width: 23vw;
          height: 25vw;
          display: flex;
          flex-wrap: wrap;
          //align-content: space-evenly;
          flex-direction: column;
          justify-content: space-evenly;
          cursor: pointer;
        }

        .details_two {
          width: 53vw;
          display: flex;
          justify-content: space-evenly;
        }
      }
    }
  }

  .regulatory_box {
    width: 100%;
    height: 30rem;
    background-color: #f4f4f4;
    display: flex;

    .regulatory {
      display: flex;
      width: 80vw;
      justify-content: space-between;
      margin: auto;
      align-items: center;

      > div:first-child {
        width: 70vw;
        font-size: 2rem;
        display: flex;
        flex-direction: column;
        align-content: space-around;

        > p:nth-child(2) {
          margin: 1rem 0 4rem 0;
        }
      }
    }
  }

  .el-carousel__item h3 {
    color: #475669;
    font-size: 14px;
    opacity: 0.75;
    line-height: 150px;
    margin: 0;
  }

  .el-carousel__item:nth-child(2n) {
    background-color: #99a9bf;
  }

  .el-carousel__item:nth-child(2n + 1) {
    background-color: #d3dce6;
  }

  .qr_code {
    width: 18rem;
    height: 18rem;
    background-color: #abcdef;
    >img{
      width: 100%;
      height: 100%;
    }
  }
}

/deep/ .el-carousel--horizontal {
  overflow-x: hidden;
  width: 100%;
}

/deep/ .el-carousel__container {
  position: relative;
  height: 300px;
  width: 100%;
}

///deep/ .el-carousel__button {
//  display: block;
//  opacity: 0.48;
//  width: 5px;
//  height: 5px;
//  background-color: #ccc;
//  border: none;
//  outline: 0;
//  padding: 0;
//  margin: 0;
//  cursor: pointer;
//  transition: 0.3s;
//  border-radius: 50%;
//}

/deep/ .el-carousel__indicator.is-active button {
  // opacity: 1;
  background: #003781;
}

/deep/ .el-carousel__arrow {
  height: 10rem;
  width: 6rem;
  cursor: pointer;
  background-color: #303133;
  opacity: 0.3;
  border-radius: 2rem;

  &:hover {
    opacity: 0.8;
  }
}

/deep/ .el-carousel__arrow i {
  font-size: 5rem;
  color: #000;
}

/deep/ .el-carousel__arrow--left {
  left: 10rem;
}

/deep/ .el-carousel__arrow--right {
  right: 10rem;
}
::v-deep .empty_box {
  margin: 0;
  width: 20vw;
  height: auto;
}

</style>

<style lang="scss">
@function px2rem($px, $screen: 1920) {
  @return calc(100vw / (#{$screen} / 10) * #{$px} / 10);
}

@function px2vw($px) {
  @return calc(100vw * #{$px} / 1920)
}
.banner {
  height: px2vw(180);
  width: px2rem(300);
}
</style>
