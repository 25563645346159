import http from "@/utils/request.js";

// export const getHomeData = (data) =>
//   new Promise((resolve, reject) => {
//     http({
//       url: "/home/bannerList",
//       method: "get",
//       params: data,
//       success(res) {
//         resolve(res);
//       },
//       fail(err) {
//         reject(err);
//       },
//     });
//   });

// export function homeBanner(data) {
//   return http({
//     url: "/home/banner/list",
//     method: "get",
//     params: data,
//   });
// }

export const homeBanner = (data) => {
  return new Promise((resolve, reject) => {
    http({
      url: "/home/banner/list",
      method: "post",
     data,
    }).then((res) => {
      if (res.data.code === 200) {
        resolve(res);
      } else {
        reject(res);
      }
    });
  });
};
// 新闻
export const newsList = (data) => {
  return new Promise((resolve, reject) => {
    http({
      url: "/home/news/list",
      method: "post",
      data,
    }).then((res) => {
      if (res.data.code === 200) {
        resolve(res);
      } else {
        reject(res);
      }
    });
  });
};
