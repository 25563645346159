<template>
  <div class="empty_box">
    <img src="@/static/images/no_info@2x.png" alt="">
    <p>暂无信息</p>
  </div>
</template>

<script>
export default {
  name:"empty"
};
</script>

<style lang="less" scoped>
.empty_box{
  margin: 10rem auto 15rem;
  width: 20vw;
  height: 20vw;
  img{
    width: 100%;
    height: 100%;
  }
  p{
    font-size: 3rem;
    text-align: center;
    color: #003781;
    opacity: .5;
  }
}
</style>